import * as React from "react";
import { Container, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { Link } from "gatsby";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';


const EdgeqPressKit2 = () => (
  <Layout>
    <Seo title="EdgeQ | Edgeq Press Kit" />


    <section className="News-detail">
        <Container>

                <Row>

                  <div className="detail-head">
                   <Link to="/media/#August" className="back-btn"><FiArrowLeft/> Company Announcements</Link>

                  <h2>EdgeQ Raises $51M in Funding to Unify 5G and AI in an Open and Programmable Platform to Ignite Edge Networks</h2>
                  </div>

                  <div className="detail-para">
                    
              <p className="text-left"><i>Led by former Qualcomm and Intel executives, EdgeQ develops fully customizable, converged connectivity and AI on a chip for the edge network</i></p>

              <div className="ajit-social">
                <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>
            <p><b>SANTA CLARA, CA</b> – November 17, 2020 – <Link to="/">EdgeQ Inc</Link>, a 5G systems-on-a-chip company, today announced its launch from stealth with $51 million in total funding, including $38.5 million in a Series A round. Backed by renowned investors Threshold Ventures (formerly DFJ), Fusion Fund, Yahoo! co-founder Jerry Yang (AME Cloud Ventures), and an unannounced strategic customer, EdgeQ will address the untapped 5G infrastructure market as the first company to converge 5G connectivity and AI compute onto a system-on-a-chip. By unifying 5G and AI at the nearest focal point of data at the edge, EdgeQ will allow enterprises (in manufacturing, construction, energy, automotive, warehousing, surveillance, telco and other verticals) to harness private networking for disruptive applications, intelligent services, and new business models.</p>

            <p>Today’s connectivity and compute constructs are based on legacy networks that are largely closed and monolithic. As new sets of devices (robotics, drones, autonomous vehicles, etc.) enter the network, coupling reliable connectivity and AI compute at the edge becomes essential.  Traditional, purpose-built fixed hardware can no longer scale efficiently and economically to support 5G service-oriented applications. A new modern approach is required to harness fast connectivity and AI compute at both the devices and infrastructure. EdgeQ will deliver a converged 5G and AI silicon platform that is open and software programmable for both devices and edge infrastructure. By introducing open programmability to the baseband, EdgeQ provides a new software-driven development model for OEMs and operators that can support existing cellular protocols, such as 4G, 5G and beyond.</p>

            <p>“We are rapidly evolving from a smartphone economy to a constellation of intelligent edge devices.  This will cause massive disruption to the current paradigm, where existing fixed-function approaches are inadequate to meet the scale, speed, and breadth of new end connections,” said Vinay Ravuri, CEO and founder of EdgeQ.  “By building 5G and AI hardware in a newly imaginative, software-friendly manner, we empower and inspire customers with an open and programmable platform that is adaptable, configurable, and economical for 5G-based applications.”</p>

            <p>To execute this vision, EdgeQ assembled a distinguished team of semiconductor, 5G, Wi-Fi, and AI industry veterans with decades of leadership experience from Qualcomm, Intel, and Broadcom. Collectively, EdgeQ’s leadership team has successfully delivered decades of cellular modem technology that powers much of today’s cellular handset and infrastructure market, as well as up-and-coming AI acceleration for data centers. </p>

            <p>“The 5G ecosystem is nascent today. The market has a limited set of players delivering 5G solutions designed around smartphones and legacy networks. There is a huge opportunity for innovation and disruption particularly for U.S. companies,” said Mohammad Islam, Partner, Threshold Ventures. “EdgeQ is poised to capture that and become the leading U.S. 5G company. With an elite founding team, they have developed a groundbreaking software-defined platform that serves a market set to grow exponentially during the next decade. As the first company to combine compute and 5G connectivity in a single chip, EdgeQ is at the forefront of the critical 5G infrastructure space.”</p>
            
            <b>The Industry’s First Open, 5G and AI on a Chip</b>
              <br />
              <br />
            <p>EdgeQ is developing the world’s first fully software-customizable silicon platform that offers 5G connectivity and AI at the edge in a disruptively simple, accessible, and frictionless manner. 5G connectivity is comprised of the fundamental backbone that fuels edge intelligence, edge cloud, and associated services. According to a recent IHS Markit report, from 2020-2035, global 5G technology will enable $13.2 trillion in economic output. But to meet this forecast, the world needs intelligent AI-enabled 5G infrastructure, a market worth approximately $10 billion.</p>

            <p>“To deliver on the promise of 5G, the market needs flexible, software-programmable silicon that can be fit-for-purpose. For example, autonomous vehicles and robotics will require both high-quality 5G connectivity and powerful AI computing capabilities,” said Jerry Yang, founding partner at AME Cloud Ventures and co-founder of Yahoo!. “Today, chips are designed for very limited use cases. EdgeQ CEO Vinay Ravuri brings the expertise needed to break this paradigm and deliver true innovation. This technology will disrupt the market for silicon and democratize access to 5G for the first time.”</p>

            <p>Learn more about how EdgeQ is pioneering the 5G infrastructure market and explore employment opportunities, visit <Link to="/">EdgeQ Inc</Link></p>
              <b>About EdgeQ</b>
              <br />
              <br />
              <p>EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering a converged connectivity and AI that is fully software-customizable and programmable. The company is backed by leading investors, including Threshold Partners, Fusion Fund and AME Cloud Ventures, among others. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link></p>

</div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default EdgeqPressKit2;
